import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import React, { useState } from 'react';

export const Gallery = (props) => {

  const [isOpen, setIsOpen] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);
  const [cantierIndex, setCantierIndex] =  useState(0);


  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Realizzazioni</h2>
          <p>
            La qualità dei nostri lavori.
          </p>
        </div>
        <div className='row'>
          <div className='portfolio-items'>
          {props.data
            ? props.data.map((d,i) => (
              <div key={`${d.Nome}-${i}`} className='col-sm-6 col-md-4 col-lg-4' onClick={() => {setIsOpen(true); setImgIndex(0); setCantierIndex(i)}}>
              {/* <GalleryChilds key={`child${i}`} data={d} />  */}
                <div className='portfolio-item'>
                    <div className='hover-bg'>        
                        <div className='hover-text'>
                          <h4>{d.Nome}</h4>
                        </div>
                        <img
                          src={d.Foto[0].imgSmall}
                          className='img-responsive'                            
                        />    
                    </div>
                </div>
              </div>             
              ))              
            : 'Loading...'}          
              {isOpen && <Lightbox key={'galleryChilds'} id={'galleryChilds'}
              imageTitle={props.data[cantierIndex].Foto[imgIndex].name}
              mainSrc={props.data[cantierIndex].Foto[imgIndex].img}
              nextSrc={props.data[cantierIndex].Foto[(imgIndex + 1) % props.data[cantierIndex].Foto.length].img}
              prevSrc={props.data[cantierIndex].Foto[(imgIndex + props.data[cantierIndex].Foto.length - 1) % props.data[cantierIndex].Foto.length].img}
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() => setImgIndex((imgIndex + props.data[cantierIndex].Foto.length - 1) % props.data[cantierIndex].Foto.length)}
              onMoveNextRequest={() => setImgIndex((imgIndex + 1) % props.data[cantierIndex].Foto.length)}                    
            />}
          </div>
        </div>
      </div>
    </div>
  )
}
