
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import React, { useState } from 'react';

export const Certifications = (props) => {
  
  const [isOpen, setIsOpen] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);

  return (
    <div id='certifications' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Certificazioni</h2>
          <p>
            In questi anni abbiamo acquisito molte competenze molte dei quali accompagnate da certificazioni.
          </p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-md-2 col-md-custom-2'>
                  {' '}
                  <img src={d.img} className='img-responsive'  alt={d.title} onClick={() => {setIsOpen(true); setImgIndex(i);}}/>
                  <p>{d.text}</p>
                </div>
              ))
              
            : 'Loading...'}
            {isOpen && <Lightbox key={'certifications'} id={'certifications'}
                    imageTitle={props.data[imgIndex].title}
                    mainSrc={props.data[imgIndex].img}
                    nextSrc={props.data[(imgIndex + 1) % props.data.length].img}
                    prevSrc={props.data[(imgIndex + props.data.length - 1) % props.data.length].img}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setImgIndex((imgIndex + props.data.length - 1) % props.data.length)}
                    onMoveNextRequest={() => setImgIndex((imgIndex + 1) % props.data.length)}                    
                  />}
        </div>
      </div>
    </div>
  )
}
