export const Navigation = (props) => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
      <div className='navbar-header'>
      <a className='navbar-brand page-scroll' href='#page-top'>
        {/* <svg 
              id="Livello_2" 
              xmlns="http://www.w3.org/2000/svg" 
              width={150}
              height={150}               
              viewBox="0 0 338.34 260.49">
                <g id="Livello_1-2">
                  <g>
                    <g>
                      <path d="M40.36,221.4h4.52v29.41h-4.52v-29.41Z" fill="#0e3300"/>
                      <path d="M69.68,240.09v10.71h-4.4v-11.18c-.04-3.07-1.67-4.87-4.05-4.87-2.56,0-5.04,1.41-5.04,5.33v10.71h-4.4v-19.93h4.4v2.99c1.37-2.73,4.65-3.41,6.27-3.41,3.2,0,5.12,1.41,6.27,3.97,1.83-3.46,4.91-3.97,6.7-3.97,4.95,0,7.68,3.2,7.68,8.88v11.48h-4.4v-11.1c0-3.12-1.66-4.95-4.22-4.95-2.9,0-4.82,2.09-4.82,5.33Z" fill="#0e3300"/>
                      <path d="M93.45,233.82c1.24-2.18,3.93-3.37,6.66-3.37,5.38,0,9.9,4.14,9.9,10.41s-4.52,10.37-9.9,10.37c-2.73,0-5.42-1.07-6.66-3.29v12.55h-4.4v-29.62h4.4v2.94Zm6.1,13.19c3.29,0,5.97-2.39,5.97-6.06s-2.69-6.23-5.97-6.23-6.06,2.35-6.06,6.23,2.99,6.06,6.06,6.06Z" fill="#0e3300"/>
                      <path d="M119.31,250.8h-4.4v-19.93h4.4v4.57c1.02-3.58,2.95-4.99,5.46-4.99,1.41,0,2.9,.3,3.54,.77l-.73,4.05c-1.15-.55-2.09-.68-2.94-.68-3.76,0-5.33,3.76-5.33,9.05v7.17Z" fill="#0e3300"/>
                      <path d="M150.42,242.4h-15.83c.47,3.07,2.86,4.99,6.1,4.99,2.22,0,4.4-1.02,5.42-2.86l3.58,1.58c-1.71,3.54-5.51,5.12-9.22,5.12-5.63,0-10.24-4.14-10.24-10.46s4.61-10.33,10.24-10.33,9.99,4.01,9.99,10.33c0,.47,0,1.15-.04,1.62Zm-4.27-3.2c-.47-3.12-2.65-4.91-5.68-4.91s-5.42,1.83-5.89,4.91h11.57Z" fill="#0e3300"/>
                      <path d="M159.77,242.18c-4.27-1.15-5.8-3.24-5.5-6.49,.21-2.94,3.5-5.25,7.13-5.25s7.04,1.62,7.26,5.89h-4.18c-.17-1.49-1.54-2.13-2.9-2.13-1.15,0-2.35,.43-2.82,1.11-.34,.55-.38,1.15-.17,1.79,.3,.81,1.37,1.24,2.43,1.54l3.07,.77c4.27,1.02,5.21,3.84,5.21,5.8,0,3.84-3.37,6.02-7.94,6.02-3.76,0-7.51-2.3-7.64-6.53h4.05c.21,1.71,1.58,2.86,3.93,2.86,2.01,0,3.54-.9,3.54-2.39,0-1.32-1.28-2.01-2.77-2.35l-2.69-.64Z" fill="#0e3300"/>
                      <path d="M189.6,247.94c-1.37,2.18-4.14,3.29-6.7,3.29-5.33,0-10.11-4.05-10.11-10.41s4.78-10.37,10.11-10.37c2.39,0,5.33,.98,6.7,3.24v-2.82h4.35v19.93h-4.35v-2.86Zm-6.19-.98c3.24,0,6.15-2.26,6.15-6.19s-3.16-6.06-6.15-6.06c-3.29,0-6.1,2.39-6.1,6.06s2.82,6.19,6.1,6.19Z" fill="#0e3300"/>
                      <path d="M211.71,221.4h17.71v4.18h-13.19v8.71h11.52v4.14h-11.52v8.24h13.61v4.14h-18.14v-29.41Z" fill="#0e3300"/>
                      <path d="M250.03,247.9c-1.24,2.18-4.01,3.33-6.74,3.33-5.38,0-9.94-4.1-9.94-10.37s4.57-10.41,9.94-10.41c2.86,0,5.51,1.11,6.74,3.29v-13.7h4.35v30.77h-4.35v-2.9Zm-6.19-13.19c-3.29,0-5.97,2.39-5.97,6.06s2.69,6.19,5.97,6.19,6.1-2.3,6.1-6.19-3.16-6.06-6.1-6.06Z" fill="#0e3300"/>
                      <path d="M262.7,220.03c1.67,0,2.9,1.2,2.9,2.77s-1.24,2.77-2.9,2.77c-1.58,0-2.94-1.19-2.94-2.77s1.37-2.77,2.94-2.77Zm2.18,30.77h-4.35v-19.93h4.35v19.93Z" fill="#0e3300"/>
                      <path d="M271.03,220.03h4.4v30.77h-4.4v-30.77Z" fill="#0e3300"/>
                      <path d="M300.47,242.4h-15.83c.47,3.07,2.86,4.99,6.1,4.99,2.22,0,4.4-1.02,5.42-2.86l3.58,1.58c-1.71,3.54-5.51,5.12-9.22,5.12-5.63,0-10.24-4.14-10.24-10.46s4.61-10.33,10.24-10.33,9.99,4.01,9.99,10.33c0,.47,0,1.15-.04,1.62Zm-4.27-3.2c-.47-3.12-2.65-4.91-5.68-4.91s-5.42,1.83-5.89,4.91h11.57Z" fill="#0e3300"/>
                    </g>
                    <path d="M290.09,208.35H35.1c-1.38,0-2.5-1.12-2.5-2.5V49.61c0-1.38,1.12-2.5,2.5-2.5h8c1.38,0,2.5,1.12,2.5,2.5V194.35c0,.55,.45,1,1,1h243.48c1.38,0,2.5,1.12,2.5,2.5v8c0,1.38-1.12,2.5-2.5,2.5Z" fill="#67a837"/>
                    <g>
                      <path d="M91.09,158.29c5.6,1.38,8.44,6.59,8.44,10.61,0,7.38-5.27,13.64-14.11,13.64h-19.18v-45.42h17.8c7.98,0,13.05,4.68,13.05,12.33,0,3.36-1.45,7.05-6,8.83Zm-7.65-2.9c3.1,0,4.88-2.37,4.88-5.47s-1.78-5.27-5.14-5.27h-8.5v10.74h8.77Zm-8.77,7.05v12.59h10.28c3.69,0,5.73-3.1,5.73-6.26,0-2.97-2.04-6.33-5.93-6.33h-10.09Z" fill="#0e3300"/>
                      <path d="M108.04,137.13h28.08v7.52h-19.71v11.8h17.27v7.45h-17.27v11.14h20.37v7.51h-28.74v-45.42Z" fill="#0e3300"/>
                      <path d="M145.74,137.13h8.44v37.9h17.53v7.51h-25.97v-45.42Z" fill="#0e3300"/>
                      <path d="M184.9,169.3c.2,3.36,2.97,6.39,7.84,6.39,4.55,0,7.32-2.37,7.52-5.47,.2-2.97-1.91-5.01-6.26-6.2l-5.6-1.45c-9.89-2.83-11.47-8.7-11.47-12.66,0-7.98,6.92-13.32,15.29-13.32s15.1,5.27,15.1,13.71h-8.44c0-3.62-2.57-5.93-6.79-5.93-3.76,0-6.79,2.11-6.79,5.34,0,1.32,.53,4.02,5.41,5.21l5.6,1.65c10.22,2.83,12.72,8.5,12.39,13.84-.33,8.17-7.71,12.85-15.95,12.85-9.69,0-16.28-6.2-16.28-13.97h8.44Z" fill="#0e3300"/>
                      <path d="M252.2,137.13v45.42h-8.5v-18.79h-18.19v18.79h-8.37v-45.42h8.37v19.05h18.19v-19.05h8.5Z" fill="#0e3300"/>
                      <path d="M263.54,137.13h8.37v45.42h-8.37v-45.42Z" fill="#0e3300"/>
                    </g>
                    <path d="M45.6,76.88v-27.26c0-1.38-1.11-2.5-2.5-2.5h-8c-1.38,0-2.5,1.12-2.5,2.5v35.41L1.17,104.72c-.73,.46-1.14,1.23-1.17,2.04-.02,.48,.1,.97,.38,1.41l4.25,6.78c.73,1.17,2.28,1.52,3.45,.79l24.52-15.37h0l13-8.15h0L167.67,15.73c.81-.51,1.85-.51,2.66,0l159.94,100.22c.13,.08,.26,.14,.4,.2,.83,.33,1.76,.19,2.45-.34,.23-.18,.44-.39,.6-.65l1.71-2.73,2.54-4.05c.67-1.06,.43-2.42-.5-3.22-.1-.08-.19-.16-.3-.23l-69.71-43.68L170.33,.38c-.81-.51-1.84-.51-2.65,0L99.1,43.35l-53.5,33.52h0" fill="#bb4d00"/>
                  </g>
                </g> 
                
              </svg>*/}
              <img src="img/logo1.svg" className="img-logo-header" alt="" />{" "}
              </a>                 
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href='#' className='page-scroll'>
                Home
              </a>
            </li>
            <li>
              <a href='#about' className='page-scroll'>
                Chi Siamo
              </a>
            </li>
            <li>
              <a href='#services' className='page-scroll'>
                Servizi
              </a>
            </li>
            <li>
              <a href='#certifications' className='page-scroll'>
                Certificazioni
              </a>
            </li>
            <li>
              <a href='#portfolio' className='page-scroll'>
                Realizzazioni
              </a>
            </li>                        
            <li>
              <a href='#contact' className='page-scroll'>
                Contatti
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
